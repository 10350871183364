<template>
  <div ref="menuManagement">
    <div class="form-area" ref="form">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="72px"
      >
        <el-form-item>
          <el-button type="primary" size="small" @click="onAddMenu">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      row-key="id"
      border
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
    >
      <el-table-column prop="name" label="名称"></el-table-column>
      <el-table-column prop="path" label="路径"></el-table-column>
      <!-- <el-table-column prop="permission" label="权限值"></el-table-column> -->
      <el-table-column prop="sort" label="排序值"></el-table-column>
      <el-table-column prop="status" label="是否锁定菜单" width="150">
        <template slot-scope="scope">
          <el-switch
            @change="changeStatus($event,scope.row)"
            v-model="scope.row.status"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="260">
        <template slot-scope="scope">
          <el-button
            icon="el-icon-edit-outline"
            type="text"
            size="small"
            sort="primary"
            @click="onUpdateMenu(scope.row)"
          >编辑</el-button>
          <el-button
            icon="el-icon-plus"
            type="text"
            size="small"
            sort="authorize"
            @click="onAddChildMenu(scope.row)"
          >新增子菜单</el-button>
          <el-button
            icon="el-icon-delete"
            type="text"
            size="small"
            sort="danger"
            @click="onDeleteMenu(scope.row)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :title="title" v-if="dialogVisible" :visible.sync="dialogVisible" width="30%">
      <div>
        <div class="body"></div>
        <addMenu
          ref="addMenu"
          :menuId="menuId"
          :title="title"
          :parentId="parentId"
          @onDialogClose="onDialogClose"
          :loadData="loadData"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false">取消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="onSave"
        >{{ title.indexOf("新增") !== -1 ? "新增" : "保存"}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getMenus, deleteMenuById ,updateMenuStatus} from "@/api/lib/api.js";
import addMenu from "./components/addMenu.vue";
export default {
  name: "menuManagement",
  components: {
    addMenu,
  },
  data () {
    return {
      tableData: [],
      form: {},
      title: "",
      menuId: null,
      parentId: 0,
      dialogVisible: false,
    };
  },
  methods: {
    //加载企业数据
    loadData () {
      getMenus({}).then((res) => {
        if (res.code === 0) {
          this.tableData = res.data;
        } else {
          this.$message.error("获取菜单列表失败");
          return [];
        }
      });
    },
    //新增
    onAddMenu () {
      this.title = "新增菜单";
      this.dialogVisible = true;
      this.parentId = 0;
    },
    //新增子菜单
    onAddChildMenu (row) {
      this.title = "新增子菜单";
      this.parentId = row.id;
      this.dialogVisible = true;
    },
    //保存
    onSave () {
      this.$refs.addMenu.onSave();
    },
    onUpdateMenu (row) {
      this.title = "修改菜单信息";
      this.menuId = row.id;
      this.dialogVisible = true;
    },
    onDeleteMenu (row) {
      const h = this.$createElement;
      this.$msgbox({
        title: "消息",
        type: "warning",
        message: h("p", null, [
          h("span", null, "确定删除 "),
          h("i", { style: "color: #059370" }, row.name),
          h("span", null, " ？"),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          deleteMenuById(row.id).then((res) => {
            if (res.code === 0) {
              this.$message.success("删除成功");
              this.loadData();
            } else {
              this.$message.error("删除失败");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            showClose: true,
            message: "已取消删除",
          });
        });
    },
    // 弹窗关闭时的回调
    onDialogClose () {
      this.dialogVisible = false;
    },
    //修改状态
    changeStatus(e, row) {
      //console.log('ghggjgghgjh',row.id)
      let message = e === 1 ? "锁定" : "启用";
      updateMenuStatus({ id: row.id, status: e }).then((res) => {
        if (res.code === 0) {
          this.$message.success(`${message}成功`);
        } else {
          this.$message.error(`${message}失败`);
          row.status = e === 1 ? 0 : 1;
          //console.log(111,row)
        }
      });
    },
  },
  mounted () {
    this.loadData();
  },
};
</script>