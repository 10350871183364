<template>
	<el-form
		:model="addForm"
		:rules="rules"
		ref="addForm"
		label-width="82px"
		label-position="left"
		class="demo-form dialog-form"
	>
		<el-form-item label="菜单名称：" prop="name">
			<el-input v-model.trim="addForm.name" placeholder="请输入菜单名称"></el-input>
		</el-form-item>
		<el-form-item label="菜单图标：" prop="icon">
			<el-input v-model.trim="addForm.icon"></el-input>
		</el-form-item>
		<el-form-item label="菜单路径：" prop="path">
			<el-input v-model.trim="addForm.path" placeholder="请输入菜单路径"></el-input>
		</el-form-item>
		<el-form-item label="权限值：" prop="permission">
			<el-input v-model.trim="addForm.permission" placeholder="请输入菜单权限值"></el-input>
		</el-form-item>
		<el-form-item label="排序值：" prop="sort">
			<el-input v-model.trim="addForm.sort" placeholder="请输入菜单排序值"></el-input>
		</el-form-item>
		<!-- <el-form-item label="状态" prop="status">
			<el-select v-model.trim="addForm.status" placeholder="请选择">
				<el-option key="0" label="正常" :value="0"></el-option>
				<el-option key="1" label="锁定" :value="1"></el-option>
			</el-select>
		</el-form-item> -->
		<el-form-item label="类型" prop="type">
			<el-select v-model.trim="addForm.type" placeholder="请选择">
				<el-option key="0" label="菜单" :value="0"></el-option>
				<el-option key="1" label="按钮" :value="1"></el-option>
			</el-select>
		</el-form-item>
	</el-form>
</template>
<script>
import { insertMenu, updateMenu, getMenuById } from "@/api/lib/api.js";
export default {
	props: {
		title: {
			type: String,
			default: ""
		},
		menuId: {
			type: Number,
			default: null
		},
		parentId: {
			type: Number,
			default: 0
		},
		loadData: {
			type: Function,
			default: null
		}
	},
	data() {
		return {
			addForm: {
				id: "",
				name: "",
				icon: "",
				path: "",
				permission: "",
				sort: "",
				/* status: "", */
				type: ""
			},
			rules: {}
		};
	},
	methods: {
		// 新增/保存菜单
		onSave() {
			this.$refs.addForm.validate(valid => {
				if (valid) {
                    let data = this.addForm;
                    console.log('test',data);
					if (this.title.indexOf("新增") !== -1) {
						data = {
							...data,
							parentId: this.parentId
						};
						insertMenu(data).then(res => {
							//console.log("接口返回的数据", res);
							if (res.code === 0) {
								this.$message.success("新增成功");
								this.loadData();
							} else {
								this.$message.error("新增失败");
							}
						});
					} else {
						data = {
							...data,
							id: this.menuId,
							parentId: this.parentId
                        };
                        updateMenu(data).then(res => {
							if (res.code === 0) {
								this.$message.success("修改成功");
								this.loadData();
							} else {
								this.$message.error("修改失败");
							}
						});
					}
					this.$emit("onDialogClose");
				}
			});
		}
	},
	created() {
        if (this.menuId !== null) {
			getMenuById(this.menuId).then(res => {
				if (res.code === 0) {
					this.addForm = { ...res.data };
					this.parentId = res.data.parentId;
				}
			});
		}
    },
	mounted() {}
};
</script>